<template>
  <section class="pt-20">
    <section-heading :heading="$t('reset_password')" />
    <form class="w-full mx-auto mt-16 sm:w-81" @submit.prevent="resetPassword">
      <text-field
        v-model="form.password"
        :label="$t('form.password')"
        type="password"
        autocomplete="new-password"
        required
        class="mb-5"
        :error="form.error.password"
      />
      <text-field
        v-model="form.passwordConfirmation"
        :label="$t('form.password_confirmation')"
        type="password"
        required
        class="mb-5"
        :error="form.error.passwordConfirmation"
      />
      <span class="block mb-4 text-center text-red-500">
        {{ form.error.fail }}
      </span>
      <pill-button type="submit" class="table ml-auto">
        {{ $t('form.submit') }}
      </pill-button>
    </form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';

export default {
  name: 'ResetPassword',
  ...componentI18n('views.auth.reset_password'),
  data() {
    return {
      form: {
        error: {
          fail: '',
          password: '',
          passwordConfirmation: ''
        },
        password: '',
        passwordConfirmation: ''
      }
    };
  },
  methods: {
    ...mapActions(['updatePassword']),
    checkPasswordErrors() {
      let valid = true;
      if (this.form.password !== this.form.passwordConfirmation) {
        this.form.error.passwordConfirmation = this.$t('mismatched_passwords');
        valid = false;
      } else {
        this.form.error.passwordConfirmation = '';
      }
      if (this.form.password.length < constants.PASSWORD_MINIMUM_LENGTH) {
        this.form.error.password = this.$t('password_should_be_longer');
        valid = false;
      } else {
        this.form.error.password = '';
      }
      return valid;
    },
    async resetPassword() {
      const token = this.$route.query.token;
      if (!token) {
        this.form.error.fail = this.$t('invalid_link');
        return;
      }

      if (this.checkPasswordErrors()) {
        const response = await this.updatePassword({
          ...this.form,
          token: token.replace(' ', '+')
        });

        if (response.success === false) {
          this.form.error.fail = response.message;
        }
      }
    }
  }
};
</script>
